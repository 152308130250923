<!--
 * @Descripttion: 评论列表组件
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-08-04 14:46:00
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-12-26 15:31:37
-->
<template>
  <template v-if="!inQuery && commentList.length > 0">
    <div class="comment-list">
      <template v-for="(item, index) in commentList" :key="item?.id">
        <ul :class="{'is-top': item.is_top === 0,'children-ul': commentList.length > 3 && children && showAllChildren}">
          <li :class="{'children': item.reply_body_id !== 0}">
              <div class="comment-left">
                <UserAvatar :user="item.user"
                  :size="children ? '26' : '38'"
                  :fontSize="children? '12' : '14'"
                  :medalSize="children? '39' : '55' ">
                </UserAvatar>
                <div class="comment-text">
                    <div>
                      <span class="user-button" @click="toUserCenter(item.user.id)">
                        {{ item.user.name}}
                      </span>
                      <i type="text" class="re-user" v-if="item.replay_user_name">
                          &nbsp;回复
                          <span class="user-name"
                              @click="toUserCenter(item.reply_user)">
                          @{{ item.replay_user_name }}
                      </span>
                      :
                      </i>
                      <span :class="{'block': !item.replay_user_name, 'deleted': item.delete_status === 1}" v-html="item.body ? analyzeEmoji(formatHtml(item.body)) : ''"></span>
                    </div>

                    <template v-if="item.image_url && item.delete_status !== 1">
                      <ImagePrev :imageList="[item.image_url]" ></ImagePrev>
                    </template>

                    <p class="time">
                      {{ formatDate(item.created_at) }}
                      <template v-if="item.is_top === 1">
                        <span class="border">置顶</span>
                        <template v-if="isCurrentUser && isBlogOrForum">
                          <span class="cancel-put-on-top" @click="commentTopConfirm(item)">取消置顶</span>
                        </template>
                      </template>
                    </p>
                </div>
              </div>
              <div class="comment-operate" v-if="item.delete_status === 0">
                <!-- 只有微博、帖子模块，自己并且非已置顶评论、非回复评论显示按钮-->
                <template v-if="isCurrentUser && isBlogOrForum && topCommentId !== item.id && !item.reply_body_id">
                  <p class="icon is-top">
                      <svg @click="commentTopConfirm(item)" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="path-1-inside-1_8295_22754" fill="white">
                        <path d="M11 6.3125H13V19.3125C13 19.8648 12.5523 20.3125 12 20.3125C11.4477 20.3125 11 19.8648 11 19.3125V6.3125Z"/>
                        </mask>
                        <path d="M11 6.3125V4.3125H9V6.3125H11ZM13 6.3125H15V4.3125H13V6.3125ZM11 8.3125H13V4.3125H11V8.3125ZM11 6.3125V19.3125H15V6.3125H11ZM13 19.3125V6.3125H9V19.3125H13ZM12 18.3125C12.5523 18.3125 13 18.7602 13 19.3125H9C9 20.9694 10.3431 22.3125 12 22.3125V18.3125ZM11 19.3125C11 18.7602 11.4477 18.3125 12 18.3125V22.3125C13.6569 22.3125 15 20.9694 15 19.3125H11Z" fill="#999999" mask="url(#path-1-inside-1_8295_22754)"/>
                        <rect x="4.5" y="3.8125" width="15" height="1" rx="0.5" stroke="#999999"/>
                        <rect x="5.70711" y="12.3828" width="9" height="1" rx="0.5" transform="rotate(-45 5.70711 12.3828)" stroke="#999999"/>
                        <rect x="12.0703" y="6.01961" width="9" height="1" rx="0.5" transform="rotate(45 12.0703 6.01961)" stroke="#999999"/>
                      </svg>
                  </p>
                </template>
                <template v-if="isCurrentUser || loginUserId === item.user_id">
                  <DeleteIcon
                    :isTextShow="false"
                    :apiMethod="feedsDeleteComment"
                    :params="getParams(item.id)"
                    deleteText="这条评论"
                    @handleClick="refreshCommentList"
                    ></DeleteIcon>
                </template>
                <CommentIcon @handleClick="addComment(item)"> </CommentIcon>

                <!-- 仅在微博/论坛展示点赞 -->
                <template v-if="isBlogOrForum">
                    <LikeIconAnimation
                      :data="item"
                      type="3"
                      :isTextShow="false"
                      @handleClick="likeComment"
                    >
                    </LikeIconAnimation>
                </template>
              </div>
          </li>
          <template v-if="item.children?.length > 0">
            <CommentList
              :data="item.children"
              :id="commentId"
              :userId="userId"
              :commentType="commentType"
              @update="refreshCommentList(commentList.length)"
              :children="true">
            </CommentList>
          </template>
        </ul>
      <p class="all-comment" v-if="item.reply_body_id !== 0 && commentList.length > 3 && index == commentList.length - 1" @click="showAllChildren = !showAllChildren">
        <template v-if="showAllChildren">
            共{{commentList.length}}条回复
            <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 1024 1024"><path fill="#7972F0" d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z"></path></svg>
        </template>
        <template v-else>收起</template>
      </p>
      </template>
    </div>
    <slot name="buttonBox"></slot>

    <!-- 回复评论 -->
    <el-dialog
      v-model="dialogVisible"
      custom-class="my-dialog"
      width="695px"
      destroy-on-close
      :show-close="false"
    >
      <div class="user-detail">
        <span class="user-detail-name">@
          <span v-html="commentInfo.user && commentInfo.user.name && formatHtml(commentInfo.user.name)"></span> :
        </span>
        <span v-if="commentInfo.image_url && commentInfo.image_url.length > 0">[图片]&nbsp;</span>
        <span class="user-detail-content" v-html="commentInfo.body && analyzeEmoji(formatHtml(commentInfo.body))"></span>
      </div>
      <CommonEditor
        buttonText="回复"
        :limit="textLimit"
        @submit="replyComment"
        placeholder="留下你的神评论～"
      ></CommonEditor>
    </el-dialog>
  </template>
</template>

<script >
import { computed, reactive, toRefs, onMounted } from 'vue'
import { useStore } from 'vuex'
import { feedsCommentLists, feedsDeleteComment, postCommentTop, feedsFeedComment } from '@/apis/blogs.js'
import { dialogMsg, confirm } from '@/utils/dialogMsg.js'
import { formatDate, analyzeEmoji } from '@/utils/filterHtml.js'
import mySocket from '@/mixin/socket.js'
import { toUserCenter } from '@/utils/userCenter.js'
import LikeIconAnimation from '@/components/icon/LikeIconAnimation.vue'
import CommentIcon from '@/components/icon/CommentIcon.vue'
import DeleteIcon from '@/components/icon/DeleteIcon.vue'
import CommonEditor from '@/components/commonEditor/CommonEditor.vue'
import CommentList from './CommentList.vue'
export default {
  name: 'CommentList',
  components: {
    LikeIconAnimation,
    CommentIcon,
    DeleteIcon,
    CommonEditor,
    CommentList
  },
  emits: ['update'],
  props: {
    data: { // 评论数据
      type: Array,
      default: () => []
    },
    commentType: { // 类型 1-微博 2-论坛 4-日志 7-okr
      type: [String, Number],
      default: '1'
    },
    id: { // 评论所属id
      type: [String, Number],
      default: 0
    },
    userId: { // 评论所属用户id
      type: Number,
      default: 0
    },
    children: {
      type: Boolean,
      default: false
    },
    isVote: { // 是否投票
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      commentId: computed(() => props.id),
      userId: computed(() => props.userId),
      commentList: [],
      commentCount: 0, // 评论总数
      loginUserId: computed(() => store.state.userInfo.userInfos.id),
      isCurrentUser: computed(() => Number(props.userId) === state.loginUserId),
      page: 1,
      commentType: computed(() => props.commentType),
      isBlogOrForum: computed(() => ['1', '2'].includes(props.commentType)),
      getCommentListAuto: computed(() => ['1', '2', '7'].includes(props.commentType)), // 自动查询评论
      topCommentId: computed(() => state.commentList.filter(item => item.is_top === 1)[0]?.id || 0), // 当前置顶评论
      showAllChildren: true,
      children: computed(() => props.children),
      textLimit: computed(() => Number(props.commentType) === 7 ? '100' : '140'),
      dialogVisible: false,
      commentInfo: {},
      inQuery: false // 便于重新加载评论列表
    })

    onMounted(() => {
      if (props.data.length > 0) {
        state.commentList = props.data
      } else if (state.commentId) {
        getCommentList(props.commentType === '4' ? 3 : 10)
      }
    })
    // 获取评论列表
    function getCommentList (limit = 10) {
      state.inQuery = true
      feedsCommentLists({
        id: state.commentId,
        page: state.page,
        type: props.commentType,
        limit: limit
      })
        .then((res) => {
          if (res.code === 0) {
            if (state.page === 1) {
              state.commentList = res.data.data
            } else {
              state.commentList.push(...res.data.data)
            }
            state.commentCount = res.data.total // 评论总数
            emit('update', res.data.feed_comment_count) // 更新包括了子评论的评论条数
          }
        }).catch((err) => {
          console.log(err)
          // item.limit = 10
        }).finally(() => {
          state.inQuery = false
          store.commit('setLoadMore', false)
        })
    }

    const loadMore = () => {
      state.page++
      getCommentList()
    }

    const hiddenComment = () => {
      state.commentList = []
      document.documentElement.style.scrollBehavior = 'smooth'
      // 滚动到锚文本的上一个元素位置
      const height = document.querySelector(`#comment_${state.commentId}`).offsetTop - 70
      if (height < document.scrollingElement.scrollTop) {
        document.documentElement.style.scrollBehavior = 'smooth'
        document.scrollingElement.scrollTop = height
        setTimeout(() => {
          document.documentElement.style.scrollBehavior = 'auto'
        }, 1000)
      }
    }

    // 添加评论
    const addComment = (item) => {
      state.dialogVisible = true
      state.commentInfo = item
    }
    const getParams = (id) => {
      const data = {
        id,
        target_id: state.commentId,
        type: props.commentType
      }
      return data
    }

    const likeComment = (item) => {
      if (item.is_like === 1) {
        item.is_like = 2
        item.like_count++
      } else {
        item.is_like = 1
        item.like_count--
      }
    }

    // 置顶/取消置顶确认
    const commentTopConfirm = (item) => {
      const message = item.id === state.topCommentId ? '确定要取消当前置顶嘛?' : '确定要替换当前置顶嘛?'
      if (state.topCommentId) {
        confirm({
          message: message,
          success: function () {
            commentTop(item)
          },
          cancel: function () {}
        })
      } else {
        commentTop(item)
      }
    }

    // 置顶评论
    const commentTop = async (item) => {
      const { code, data: { type } } = await postCommentTop({ id: item.id })
      if (code === 0) {
        item.is_top = type
        dialogMsg('success', type === 1 ? '置顶成功' : '取消置顶成功')
        refreshCommentList()
      }
    }
    const { sendToSocket } = mySocket()

    // 回复评论
    const replyComment = (data, callback) => {
      feedsFeedComment({
        id: props.id, // 动态ID
        commentable_type: props.commentType, // 当前类型
        body: data.feed_content, // 回复内容
        reply_user: state.commentInfo.user_id, // 评论人的用户ID
        reply_body_id: state.commentInfo.id, // 当前评论ID
        at_user: data.at_user,
        images_url: data.images_url
      }).then((res) => {
        if (res.code === 0) {
          dialogMsg('success', '评论成功')
          const socketArr = [state.commentInfo.user_id]
          state.dialogVisible = false
          sendToSocket(socketArr, { type: 5 })
          sendToSocket(data.at_user, { type: 4 })
          refreshCommentList()
        } else if (res.code === 30007) {
          dialogMsg('warning', '内容已被删除')
        } else {
          dialogMsg('error', res.msg)
        }
        callback(res.code)
      })
        .catch((err) => {
          callback()
          console.log(err)
        })
    }

    // 刷新评论数据
    const refreshCommentList = (length = state.commentList.length) => {
      state.page = 1
      getCommentList(length)
    }

    return {
      ...toRefs(state),
      getCommentList,
      refreshCommentList,
      addComment,
      getParams,
      likeComment,
      commentTopConfirm,
      replyComment,
      loadMore,
      hiddenComment,
      feedsDeleteComment,
      formatDate,
      analyzeEmoji,
      toUserCenter
    }
  }
}

</script>

<style lang="less" scoped>
.comment-list {
  padding-top: 16px;
  & > ul {
    padding-bottom: 4px;
  }
  & > ul > ul:last-child li.children:last-child {
    padding-bottom: 0 !important;
  }
  ul {
    margin: 0;
    &.is-top {
      padding-bottom: 4px;
      .all-comment {
        margin-bottom: 20px;
      }
    }
    &:hover {
      .icon {
        &.is-top {
          opacity: 1 !important;
        }
      }
    }
  }
  ul.children-ul:nth-child(n+4) {
    display: none;
  }

  li {
    position: relative;
    display: flex;
    padding-bottom: 12px;
    justify-content: space-between;

    .comment-text {
      .deleted {
        color: #B3b3b3;
        user-select: none;
      }
    }

    &.children {
      margin-left: 45px;
      .user-avatar {
        margin-right: 8px ;
      }

      .comment-operate {
        position: unset;
        min-width: 120px;
        justify-content: flex-end;
      }

      .comment-text {
        margin-top: 2px;
      }
    }

    .comment-operate {
      position: absolute;
      right: 0;
      top: -3px;
      display: flex;
      min-width: 70px;
      text-align: right;

      :deep(svg) {
        margin-left: 10px;
        cursor: pointer;

      }
      :deep(.icon) {
        position: relative;
        display: flex;
        align-items: center;
        height: 24px;
        span {
          position: absolute;
          margin-left: 40px;
          color: #777;
          font-size: 12px;
        }
        &.is-top {
          opacity: 0;
        }
      }
    }
    .user-button {
      color: #7f7f7f;
      cursor: pointer;
      &:hover {
        color: @active-text-color;
      }
    }
  }
  .comment-text {
    max-width: 660px;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 14px;

    .block {
      display: block;
      margin-top: 8px;
    }

    :deep(.el-image) {
      display: block;
      margin-top: 7px;
      max-width: 100px;
      max-height: 100px;
      overflow: hidden;
    border-radius: 4px;
    }

    .user-button {
      min-height: 0;
      padding: 0;
      margin: 0;
      color: @default-text-color;
    }
    .time {
      color: #999;
      margin-top: 8px;
      font-size: 12px;

      .border {
        margin: 0 12px 0 8px;
        padding: 1px 4px;
        color: #FF8401;
        border: solid 1px #FF8401;
        border-radius: 4px;
        &+span {
          cursor: pointer;
        }
      }

    }
    a {
      color: @active-text-color;
      display: inline-block;
    }
    :deep(span>p){
      display: contents;
    }
  }

  .all-comment {
    display: inline-block;
    color: @active-text-color;
    margin: 0 0 20px 45px;
    font-size: 14px;
    cursor: pointer;
  }

  .re-user {
    font-style: normal;
    text-align: right;
    font-size: 14px;
    span.user-name {
      color: @active-text-color;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .comment-left {
    display: flex;
    justify-content: flex-start;
    .user-medal:before {
      transform: translate(-7px, -12%);
    }
  }
  .cancel-put-on-top:hover,
  :deep(.user-button) {
    color: @active-text-color;
  }
}
:deep(.user-detail-content a) {
  color: @active-text-color !important;
}
.user-detail {
  display: flex;
  .user-detail-name {
    display: flex;
    padding-right: 10px;
  }
  .user-detail-content {
    flex: 1;
  }
}
</style>
